import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import { getMessaging } from 'firebase/messaging/sw'

const firebaseConfig = {
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

const appFirebase = firebase.initializeApp(firebaseConfig)
const messaging = getMessaging(appFirebase)

export { firebase, appFirebase, messaging }
