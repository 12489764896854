import { App } from 'vue'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import { BToastPlugin } from 'bootstrap-vue-3'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import mitt from 'mitt'
import { checkAuthRoute } from '@/utils/middleware'
import { dateFormat, dateTimeFormat, dateTimeSecondFormat, formatCurrencyLabel, fromNow, getCurrencyPrecision, replaceHiddenText, toCurrency, toNumber, toNumberNoRound } from '@/utils/filter'
import { charLimit, copy, getImage, getMessageErrorCode, getNameOrderStatus, getNoCover, getNoImage, getNoImage2, getUrl, getUrlUpload, NO_COVER, NO_IMAGE, NO_IMAGE2, scrollToTop } from '@/utils/global'
import SocketIO from '@/utils/socket'
import Vue3Mq from '@/utils/vue3-mq'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
//@ts-ignore
import ReadMore from 'vue-read-more'
import { Skeletor } from 'vue-skeletor'
//@ts-ignore
import DisableAutocomplete from 'vue-disable-autocomplete'

export const Utils = {
    install: (app: App) => {
        app.config.globalProperties.$filters = {
            dateFormat,
            dateTimeFormat,
            dateTimeSecondFormat,
            fromNow,
            getCurrencyPrecision,
            toNumberNoRound,
            toCurrency,
            toNumber,
            formatCurrencyLabel,
            getMessageErrorCode,
            replaceHiddenText,
            charLimit,
            scrollToTop,
        }

        app.use(checkAuthRoute)
        app.use(SocketIO)
        app.use(VueClipboard)
        app.use(Vue3Mq)
        app.use(BToastPlugin)
        app.use(FloatingVue)
        app.use(ReadMore)
        app.use(DisableAutocomplete)

        app.component('Datepicker', Datepicker)
        app.component(Skeletor.name, Skeletor)

        const emitter = mitt()

        app.provide('getNoImage', getNoImage)
        app.provide('getNoImage2', getNoImage2)
        app.provide('getNameOrderStatus', getNameOrderStatus)
        app.provide('emitter', emitter)
        app.provide('getImage', getImage)
        app.provide('getUrl', getUrl)
        app.provide('getNoCover', getNoCover)
        app.provide('copy', copy)
        app.provide('NO_IMAGE', NO_IMAGE)
        app.provide('NO_IMAGE2', NO_IMAGE2)
        app.provide('NO_COVER', NO_COVER)
        app.provide('getUrlUpload', getUrlUpload)
    },
}
