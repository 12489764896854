import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'order/:type(buy|sell)?',
            name: 'order.index',
            component: Helpers.lazyload('order/index'),
            meta: { auth: true },
        },
        {
            path: 'order/:id',
            name: 'order.detail',
            component: Helpers.lazyload('order/detail'),
            meta: { auth: true },
        }
    ],
}
