import { App } from 'vue'
// @ts-ignore
import { Vue3Mq, MqResponsive, useMq } from 'vue3-mq'

export default {
    install: (app: App) => {
        app.use(Vue3Mq, {
            preset: 'bootstrap5',
            breakpoints: {
                xs: 0,
                sm: 375,
                md: 900,
                lg: 1025,
                xl: 1240,
            },
        })
        app.provide('MqResponsive', MqResponsive)
    },
}
