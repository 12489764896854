import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'trading',
            name: 'trading.index',
            redirect: { name: 'trading.sell' },
        },
        {
            path: 'trading/buy/:asset?',
            name: 'trading.buy',
            component: Helpers.lazyload('trading/index'),
        },
        {
            path: 'trading/sell/:asset?',
            name: 'trading.sell',
            component: Helpers.lazyload('trading/index'),
        },
    ],
}
