import { defineStore } from 'pinia'

interface SocketStoreState {
    socketP2PConnected: boolean
    socketP2PLogin: boolean
}

interface SocketStoreAction {
    connectedSocket(payload?: any): void
    loginSocket(payload?: any): void
}

export interface SocketStore extends SocketStoreState, SocketStoreAction {
}

export const useSocketStore = defineStore('socket', {
    state() {
        return {
            socketP2PConnected: false,
            socketP2PLogin: false,
        }
    },
    actions: {
        connectedSocket(payload?: any): void {
            const {
                socketP2PConnected,
            } = payload
            this.socketP2PConnected = socketP2PConnected ?? false
        },

        loginSocket(payload?: any): void {
            const {
                socketP2PLogin,
            } = payload
            this.socketP2PLogin = socketP2PLogin ?? false
        },
    },
})
