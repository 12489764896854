import moment from 'moment-timezone'
import { find, trimEnd, uniqBy } from 'lodash'
import numeral from 'numeral'
import { getLanguage } from '@/utils/common'
import { AppStore, useAppStore } from '@/stores/appStore'

let locale = getLanguage()?.toString() || process.env.VUE_APP_LOCALE
moment.locale(locale)
moment.updateLocale('vi', {
    months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
    monthsShort: ['Thg 1', 'Thg 2', 'Thg 3', 'Thg 4', 'Thg 5', 'Thg 6', 'Thg 7', 'Thg 8', 'Thg 9', 'Thg 10', 'Thg 11', 'Thg 12'],
    weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
    weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    week: {
        dow: 1,
    },
    firstDayOfWeek: 1,
    relativeTime: {
        M: '1 tháng',
        d: '1 ngày',
        h: '1 giờ',
        m: '1 phút',
        w: '1 tuần',
        y: '1 năm',
    },
})

export const dateFormat = (value: string | number): string => {
    return moment(value).format('YYYY-MM-DD')
}

export const dateTimeFormat = (value: string | number): string => {
    return moment(value).format('YYYY-MM-DD HH:mm')
}

export const dateTimeSecondFormat = (value: string | number): string => {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

export const fromNow = (value: string | number): string => {
    return moment(value).fromNow()
}

export const getCurrencyPrecision = function (currency: string): number {
    if (currency == 'VND' || currency == 'VNDT') return 0
    if (currency === 'BCDB_ETWD' || currency === 'ETWD') return 0
    if (currency === 'USDT' || currency === 'TRX_USDT') return 2
    if (currency === 'BTC' || currency === 'ETH' || currency === 'BNB' || currency === 'ADA') return 8
    return 8
}

export const formatCurrencyLabel = function (currency: string): string {
    const appStore: AppStore = useAppStore()
    let currencies = uniqBy([...appStore?.coinTrading, ...appStore?.listFiat], 'code')
    const label = find(currencies, item => item?.code === currency)?.label
    return label ? label.toString() : ''
    // let name = ''
    // if (currency == 'VND' || currency == 'VNDT') name = 'VNDT'
    // if (currency === 'BCDB_ETWD' || currency === 'ETWD') name = 'ETWD'
    // if (currency === 'TRX_USDT') name = 'USDT (TRC-20)'
    // return name
}

export const toNumber = (value: number, minimumDecimal = 0, maximumFractionDigits = 8, locale = 'en-US'): string => {
    const formatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: minimumDecimal,
        maximumFractionDigits: maximumFractionDigits,
    })
    return formatter.format(value)
}

export const toNumberNoRound = (value: any, maximumFractionDigitsDisplay = 2): string => {
    const truncateFractionAndFormat = (value: any, maxDigits: any) => {
        let returnValue = numeral(value).format('0,0[.]' + '0'.repeat(maxDigits), Math.floor)
        // Kiểm tra có phải là số thập phân
        if (returnValue.indexOf('.') != -1) {
            // Bỏ những số 0 cuối
            returnValue = trimEnd(returnValue, '0')
        }
        // Kiểm tra nếu dấu . ở cuối sau khi bỏ số 0
        if (returnValue.substr(-1) === '.') {
            returnValue = returnValue.replace('.', '')
        }
        return returnValue
    }
    return truncateFractionAndFormat(value, maximumFractionDigitsDisplay)
}

export const toCurrency = (value: number, currency: any, minimumFractionDigits = 3, locale = 'en-US'): string => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits,
    })
    return formatter.format(value) + ' ' + currency
}

export const displayDecimalAsNumber = (value: any, maxPrecision = 0): number => {
    if (maxPrecision <= 1) {
        return Math.floor(value * 10) / 10
    }
    const decimalAfter = maxPrecision === 2 ? 100 : (maxPrecision * 10) * 100
    return Math.floor(value * decimalAfter) / decimalAfter
}

export const replaceHiddenText = (value: string | number): string => {
    let textReplace: string
    if (!value || value.toString().length <= 3) {
        textReplace = '****'
    } else {
        let first = value.toString().slice(0, 3)
        let last = value.toString().slice(-3)
        textReplace = first + '****' + last
    }
    return textReplace
}
