import { filter, trim, truncate } from 'lodash'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import { getLanguage, getToken } from '@/utils/common'
import { ToastService } from '@/plugins/Toast'
import { i18n } from '@/plugins/SetupI18n'
import { AppStore, useAppStore } from '@/stores/appStore'
import Vue from 'vue'

export const ORDER_STATUS = {
    STATUS_CANCELLED_BY_ME: 7,
    STATUS_CANCELLED_BY_PARTNER: 6,
    STATUS_CANCELLED_BY_TIMER: 8,
    STATUS_COMPLETED: 4,
    STATUS_WAIT_CONFIRM: 2,
    STATUS_WAIT_PAY: 1,
    STATUS_WAIT_SYSTEM: 9,
}

export const NO_IMAGE = require('@/assets/images/error/no-image.png')

export const NO_IMAGE2 = require('@/assets/images/error/no-image2.png')

export const NO_COVER = require('@/assets/images/error/no-cover.png')

export const getNoImage = (e: any) => {
    return e.target.src = NO_IMAGE
}

export const getNoImage2 = (e: any) => {
    return e.target.src = NO_IMAGE2
}

export const getNoCover = (e: any) => {
    return e.target.src = NO_COVER
}

export const getNameOrderStatus = (status: number): string => {
    let name = '--'
    switch (status) {
        case 9:
            name = i18n.global.t('theme/order.appealing')
            break
        case 8:
            name = i18n.global.t('p2p::backend/order.TIMER_CANCELED')
            break
        case 7:
            name = i18n.global.t('p2p::backend/order.CANCELED')
            break
        case 6:
            name = i18n.global.t('p2p::backend/order.CANCEL')
            break
        case 4:
            name = i18n.global.t('p2p::backend/order.COMPLETE')
            break
        case 2:
            name = i18n.global.t('p2p::backend/order.PAYMENT')
            break
        case 1:
            name = i18n.global.t('p2p::backend/order.WAIT_PAYMENT')
            break
    }
    return name
}

export const filteredOptions = (options: any = [], keyword?: string | null) => {
    if (keyword && keyword.length > 0) {
        return filter(options, item => {
            if (typeof item === 'object') {
                return (item.text.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
            } else {
                return (item.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
            }
        })
    }
    return options
}

export const getImage = (url?: string): string => {
    // return process.env.VUE_APP_WALLET_URL + '/images/' + url  // Nếu url không đủ link
    return url ? url.toString() : ''
}

export const getUrlUpload = (url: any): string => {
    return process.env.VUE_APP_UPLOAD + url
}

export const getUrl = (slug: string) => {
    return window.location.protocol + '//' + window.location.host + '/' + getLanguage() + '/' + 'profile' + '/' + slug
}

export const copy = (value: any) => {
    if (value) {
        toClipboard(value).then(() => {
            ToastService.$success(i18n.global.t('theme/profile.copied') + '!', true)
        }).catch(() => {
            ToastService.$error(i18n.global.t('add/btn_copy_failed'), true)
        })
    } else {
        ToastService.$error(i18n.global.t('add/btn_copy_failed'), true)
    }
}

export const getMessageErrorCode = (code: any): string => {
    const messageError: any = {
        0: i18n.global.t('backend.alert.success'),
        1000: i18n.global.t('backend.alert.error'),
    }
    return messageError[code] ?? i18n.global.t('backend.alert.error')
}

export const walletLoginUrl = (url?: string): string => {
    const appStore: AppStore = useAppStore()
    if (!url) url = window.location.protocol + '//' + window.location.host + '/' + getLanguage()
    let walletUrl = appStore.walletUrl || process.env.VUE_APP_WALLET_URL
    return walletUrl + '/login?redirectUrl=' + encodeURIComponent(url)
}
export const getAuthWalletUrl = (redirectUrl?: string) => {
    let url = walletBaseUrl()
    const token = getToken()
    if (token) {
        if (typeof token === 'object') {
            console.log('token :>> ', token)
        }
        url += `/authentication.html?token=${token}`
        if (redirectUrl) {
            url += `&redirectUrl=${redirectUrl}`
        }
    }
    return url
}
export const goToWallet = (redirectUrl?: string) => {
    return window.location.href = getAuthWalletUrl(redirectUrl)
}
export const walletBaseUrl = (path?: string): string => {
    const appStore: AppStore = useAppStore()
    let url = appStore.walletUrl || process.env.VUE_APP_WALLET_URL
    return (url ? url.toString() : '') + (path ? path : '')
}
export const setHTMLStyle = (val?: any) => {
    const htmlElement = document.querySelector('html')
    if (htmlElement) {
        htmlElement.style.overflow = val
    }
}

export const charLimit = (str: string, length = 30, omission = '...'): string => {
    return trim(truncate(str, { length: length, omission: omission }))
}

export const isSSL = (): any => {
    if (process.env.VUE_APP_USE_HTTPS) {
        // @ts-ignore
        return process.env.VUE_APP_USE_HTTPS === true || process.env.VUE_APP_USE_HTTPS === 'true'
    }
}

export const urlApp = (): string => {
    return process.env.VUE_APP_URL ? process.env.VUE_APP_URL.toString() : 'p2p.dddc.io'
}

export const getSocketUrl = (): string => {
    let url = 'http'
    if (process.env.VUE_APP_SOCKET_PORT && +process.env.VUE_APP_SOCKET_PORT === 443 || isSSL()) {
        url += 's'
    }
    url += '://'
    url += process.env.VUE_APP_SOCKET_HOST
    if (process.env.VUE_APP_SOCKET_PORT && +process.env.VUE_APP_SOCKET_PORT !== 80) {
        url += ':' + process.env.VUE_APP_SOCKET_PORT
    }
    return url.toString()
}

export const scrollToTop = (): void => {
    window.scrollTo(0, 0)
}

export const onlyNumber = ($event: any): void => {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
    if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
    }
}
export const sleeper = (ms: any) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
export const currencyUSD = (value: number) => {
    if (!value) return '0'

    // Chuyển đổi số thành chuỗi và tách phần nguyên và phần thập phân
    let parts = value.toString().split('.')

    // Nếu có phần thập phân, cắt đến 2 chữ số mà không làm tròn
    if (parts[1]) {
        parts[1] = parts[1].slice(0, 2)
    }

    // Chuyển đổi phần nguyên từ chuỗi sang số trước khi định dạng
    parts[0] = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(Number(parts[0])) // Sử dụng Number để chuyển đổi chuỗi thành số

    // Kết hợp lại phần nguyên và phần thập phân
    return parts.join('.')

}
