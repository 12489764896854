import { defineStore } from 'pinia'
import { messaging } from '@/plugins/FirebaseService'
import { getToken, isSupported } from 'firebase/messaging'
import { setFirebaseToken } from '@/utils/common'
import { ApiService } from '@/plugins/ApiService'
import { Constant } from '@/plugins/Constant'

interface FirebaseStoreState {
    showEnableNotificationHelp: boolean
}

interface FirebaseStoreAction {
    messaging(): any

    ask(): any
}

export interface FirebaseStore extends FirebaseStoreAction, FirebaseStoreState {
}

export const useFirebaseStore = defineStore('firebase', {
    state(): FirebaseStoreState {
        return {
            showEnableNotificationHelp: false,
        }
    },
    actions: {
        ask() {
            if (('Notification' in window)) {
                if (Notification.permission.toString() === 'granted') {
                    this.messaging()
                    this.showEnableNotificationHelp = false
                } else {
                    this.showEnableNotificationHelp = true
                }
            }
        },

        messaging() {
            let registerCount = 0

            const reRegister = () => {
                if (registerCount > 3) return
                registerCount++
                allowedPermission().then().catch()
            }
            const allowedPermission = async () => {
                try {
                    if (await isSupported()) {
                        navigator.serviceWorker.register('/firebase/worker.js?t=' + new Date().getTime())
                            .then((registration) => {
                                try {
                                    if (registration.active && registration.active.state === 'activated') {
                                        try {
                                            getToken(messaging, {
                                                serviceWorkerRegistration: registration,
                                            }).then(async (refreshedToken: any) => {
                                                try {
                                                    // console.log('TOKEN FIREBASE: ' + refreshedToken)
                                                    setFirebaseToken(refreshedToken)
                                                    const response = await ApiService.instance().axiosAuthorization.post(Constant.AUTH_SAVE_FIREBASE, { messaging: refreshedToken })
                                                    if (response && response.status) {
                                                        return Promise.resolve()
                                                    }
                                                } catch (e) {
                                                    reRegister()
                                                }
                                            }).catch(() => {
                                                console.log('Error generate token')
                                                reRegister()
                                            })
                                        } catch (e) {
                                            console.log('Error generate token')
                                            reRegister()
                                        }
                                    } else {
                                        console.log('Registration not active')
                                        reRegister()
                                    }
                                } catch (e) {
                                    console.log('Registration not active')
                                    reRegister()
                                }
                            }).catch(function (v) {
                            console.log('ERROR FIREBASE: ', v)
                            reRegister()
                        })
                    }
                } catch (e) {
                    console.log('Worker not working')
                    reRegister()
                }
            }

            allowedPermission()
                .then()
                .catch()
        },
    },
})
