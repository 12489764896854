import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'profile/:slug',
            name: 'profile.index',
            component: Helpers.lazyload('profile/index'),
        },
        {
            path: 'merchant/detail/:id',
            name: 'merchant.detail',
            component: Helpers.lazyload('profile/TheMerchantDetail'),
        },
    ],
}
