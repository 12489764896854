import MainLayout from '@/views/layouts/MainLayout.vue'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: '',
            name: 'dashboard.index',
            // component: Helpers.lazyload('dashboard/index'),
            redirect: { name: 'trading.index' },
        },
    ],
}
