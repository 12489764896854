import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'myad/:type(buy|sell)?',
            name: 'myad.index',
            component: Helpers.lazyload('myAd/index'),
            meta: { auth: true },
        },
        {
            path: 'myad/edit/:id',
            name: 'myad.edit',
            component: Helpers.lazyload('myAd/edit'),
            meta: { auth: true },
        },
        {
            path: 'myad/post',
            name: 'myad.post',
            component: Helpers.lazyload('myAd/create'),
            meta: { auth: true },
        },
        {
            path: 'myad/history/:type(buy|sell)?',
            name: 'myad.history',
            component: Helpers.lazyload('myAd/history'),
            meta: { auth: true },
        },
        {
            path: 'myad/order-by-adv/:id',
            name: 'myad.order-by-adv',
            component: Helpers.lazyload('myAd/components/History/TheListOrderByAdv'),
            meta: { auth: true },
        },
    ],
}
