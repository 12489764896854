import Cookies from "js-cookie";
import moment from "moment";

class Storage {
  private readonly _isSupport: boolean;

  constructor() {
    this._isSupport = true;
  }

  static get timestamp(): number {
    return new Date().getTime() / 1000;
  }

  static __isExpired(entity: any) {
    if (!entity) return true;
    return (
      Storage.timestamp -
        (Number(entity.timestamp) + Number(entity.expired_second)) >=
      0
    );
  }

  set(key: string, value: any, expired_second = 60 * 60 * 24 * 30) {
    if (!this._isSupport) {
      return this;
    }
    if (expired_second < 1 || isNaN(expired_second))
      expired_second = 60 * 60 * 24 * 30; // default is 30 days

    if (!key && !value) {
      console.error("Missing Parameter");
      return this;
    }
    Cookies.set(key, JSON.stringify(value), {
      expires: moment().add(expired_second, "seconds").toDate(),
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
    });
    return this;
  }

  get(key: string, defaultValue = null) {
    if (!this._isSupport) {
      return defaultValue;
    }

    let entity = Cookies.get(key);
    if (entity) {
      try {
        return JSON.parse(entity);
      } catch (e) {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  }

  remove(key: string) {
    if (!this._isSupport) {
      return this;
    }
    if (!key) {
      return this;
    }
    Cookies.remove(key, {
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
    });
    return this;
  }

  clear() {
    if (!this._isSupport) {
      return null;
    }
    const cookies = Cookies.get();
    for (const key in cookies) {
      if (Object.prototype.hasOwnProperty.call(cookies, key)) {
        Cookies.remove(key, {
          domain: process.env.VUE_APP_COOKIE_DOMAIN,
        });
      }
    }
    return this;
  }
}

export const StorageServiceCookie = new Storage();
