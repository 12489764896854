import { ToastStore, useToastStore } from '@/stores/toastStore'
import { pinia } from '@/plugins/pinia'

const toastStore: ToastStore = useToastStore(pinia)

class Toast {
    private data: any

    show(toastContent: ToastItem) {
        toastStore.showToast(toastContent)
    }

    $success(title: string = '', show: Boolean = false, position: string = 'top-right', type: string = 'toast-success') {
        let toastContent: ToastItem = {
            show: show,
            type: type,
            title: title,
            position: position,
        }
        toastStore.showToast(toastContent)
    }

    $error(title: string = '', show: Boolean = false, position: string = 'top-right', type: string = 'toast-error') {
        let toastContent: ToastItem = {
            show: show,
            type: type,
            title: title,
            position: position,
        }
        toastStore.showToast(toastContent)
    }

    $warning(title: string = '', show: Boolean = false, position: string = 'top-right', type: string = 'toast-warning') {
        let toastContent: ToastItem = {
            show: show,
            type: type,
            title: title,
            position: position,
        }
        toastStore.showToast(toastContent)
    }

    $info(title: string = '', show: Boolean = false, position: string = 'top-right', type: string = 'toast-info') {
        let toastContent: ToastItem = {
            show: show,
            type: type,
            title: title,
            position: position,
        }
        toastStore.showToast(toastContent)
    }
}

export const ToastService = new Toast()
