export enum Constant {
    GLOBAL_SETTING = 'global/settings/for-app',

    SEND_CODE = 'gateway/send-code',

    ADV_LIST = 'gateway/p2p/adv',
    ADV_DETAIL = 'gateway/p2p/adv/detail',

    MY_ADV_OFFLINE = 'gateway/p2p/myad/offline',
    MY_ADV_ONLINE = 'gateway/p2p/myad/online',
    MY_ADV_CLOSE = 'gateway/p2p/myad/close',
    MY_ADV_DETAIL = 'gateway/p2p/myad/detail',
    MY_ADV_EDIT = 'gateway/p2p/myad/edit',
    MY_ADV_UPDATE = 'gateway/p2p/myad/update',
    MY_ADV_CREATE = 'gateway/p2p/myad/create',
    ORDER_BY_ADV = 'gateway/p2p/myad/order-by-adv',

    ASSET_CONFIG = 'gateway/p2p/myad/asset-config',
    ASSET_MARKET = 'gateway/p2p/myad/asset-market',

    PAYMENT_METHOD_LIST = 'gateway/p2p/config/payment-method-list',
    PAYMENT_METHOD_DETAIL = 'gateway/p2p/config/payment/detail',

    FAQS_LIST = 'gateway/p2p/faqs',
    BENEFIT_LIST = 'gateway/p2p/advantages',

    USER_PROFILE = 'gateway/user',
    USER_DETAIL = 'gateway/p2p/profile/detail',
    USER_ACTIVITIES = 'gateway/p2p/profile/p2p-activities',
    USER_REVIEW = 'gateway/p2p/profile/review',
    USER_ADV_LIST = 'gateway/p2p/myad/list',
    USER_PAYMENT_METHOD = 'gateway/p2p/config/payment/user-paymethods',
    USER_PAYMENT_METHOD_DETAIL = 'gateway/p2p/config/payment/user-paymethods/detail',
    USER_PAYMENT_METHOD_DELETE = 'gateway/p2p/config/payment/user-paymethods/delete',
    USER_PAYMENT_METHOD_ADD = 'gateway/p2p/config/payment/create',
    USER_BLACK_LIST = 'gateway/p2p/user/blacklist',
    USER_BLACK_LIST_ADD = 'gateway/p2p/user/blacklist/add',
    USER_BLACK_LIST_REMOVE = 'gateway/p2p/user/blacklist/remove',
    CREATE_MERCHANT_NAME = 'gateway/p2p/merchant/save',

    MERCHANT_DETAIL = 'gateway/p2p/merchant/detail',
    MERCHANT_ADV_LIST = 'gateway/p2p/merchant/adv-list',

    BALANCE_ASSET = 'gateway/p2p/asset/balance',
    BALANCE_FIAT = 'gateway/p2p/asset/balance-fiat',

    CHAT_LIST = 'gateway/chats',
    NOTIFICATION_LIST = 'gateway/notifications',
    NOTIFICATION_READ = 'gateway/read-notifications',
    NOTIFICATION_READ_ALL = 'gateway/read-all-notifications',

    ORDER_INDEX = 'gateway/p2p/order/index',
    ORDER_LIST = 'gateway/p2p/order/list',
    ORDER_REASON = 'gateway/p2p/order/reasons',
    ORDER_APPEAL = 'gateway/p2p/order/appeal-reasons',
    ORDER_MAKE = 'gateway/p2p/order/make',
    ORDER_CONFORM = 'gateway/p2p/order/conform',
    ORDER_UPDATE = 'gateway/p2p/order/update',
    ORDER_DETAIL = 'gateway/p2p/order/detail',
    ORDER_LOAD_MESSAGE = 'gateway/p2p/order/load-messages',
    ORDER_SEND_MESSAGE = 'gateway/p2p/order/send-message',
    ORDER_SEND_FILE = 'gateway/p2p/order/send-file',
    ORDER_SEND_FEEDBACK = 'gateway/p2p/order/send-feedback',
    ORDER_GET_FEEDBACK = 'gateway/p2p/order/get-feedback',
    ORDER_CANCEL = 'gateway/p2p/order/cancel',
    ORDER_ACTION_APPEAL = 'gateway/p2p/order/appeal',
    ORDER_ESCROW_DEPOSIT_INFO = 'gateway/p2p/order/escrow-deposit-info',

    PAGES = 'gateway/p2p/pages/detail',

    AUTH_LOGOUT = 'gateway/auth/logout',
    AUTH_LOGIN = 'gateway/auth/login',
    AUTH_VERIFY = 'gateway/auth/conform',
    AUTH_SAVE_FIREBASE = 'gateway/auth/save-firebase'
}
