import router from '@/router'
import { ApiService } from '@/plugins/ApiService'
import { getToken, hasToken } from '@/utils/common'
import { Constant } from '@/plugins/Constant'
import { walletLoginUrl } from '@/utils/global'
import { AppStore, useAppStore } from '@/stores/appStore'

export const checkAuthRoute = () => {
    router.beforeEach(async (to, from, next) => {

        const appStore: AppStore = useAppStore()

        if (to.meta && Object.prototype.hasOwnProperty.call(to.meta, 'auth')) {
            if (to.meta.auth) {
                const isLoggedIn = getToken()
                if (isLoggedIn) {
                    try {
                        const response = await ApiService.instance().axiosAuthorization.get(Constant.USER_PROFILE)
                        if (response && response.status) {
                            return next()
                        } else {
                            if (appStore?.disableClientLoginRoute) {
                                return window.location.href = walletLoginUrl()
                            } else {
                                gotoLogin(to, next)
                            }
                        }
                    } catch (e) {
                        if (appStore?.disableClientLoginRoute) {
                            return window.location.href = walletLoginUrl()
                        } else {
                            gotoLogin(to, next)
                        }
                    }
                } else {
                    if (appStore?.disableClientLoginRoute) {
                        return window.location.href = walletLoginUrl()
                    } else {
                        gotoLogin(to, next)
                    }
                }
            } else {
                return next()
            }
        } else {
            /*
            * Check đã login => return về dashboard
            * */
            if (to.name == 'auth.login') {
                if (!hasToken()) return next()
                gotoDashboard(to, next)
            } else {
                return next()
            }
        }
    })
}

export const checkAuth = async () => {
    const isLoggedIn = getToken()
    const appStore: AppStore = useAppStore()
    if (hasToken()) {
        if (isLoggedIn) {
            try {
                const response = await ApiService.instance().axiosAuthorization.get(Constant.USER_PROFILE)
                if (response && response.status) {
                    return true
                } else {
                    if (appStore?.disableClientLoginRoute) {
                        return window.location.href = walletLoginUrl()
                    } else {
                        return router.push({ name: 'auth.login' })
                    }
                }
            } catch (e) {
                if (appStore?.disableClientLoginRoute) {
                    return window.location.href = walletLoginUrl()
                } else {
                    return router.push({ name: 'auth.login' })
                }
            }
        } else {
            if (appStore?.disableClientLoginRoute) {
                return window.location.href = walletLoginUrl()
            } else {
                return router.push({ name: 'auth.login' })
            }
        }
    } else {
        if (appStore?.disableClientLoginRoute) {
            return window.location.href = walletLoginUrl()
        } else {
            return router.push({ name: 'auth.login' })
        }
    }
}

export const gotoLogin = (to?: any, next?: any) => {
    return next({
        name: 'auth.login',
        query: {
            redirect: to ? encodeURIComponent(to.fullPath) || encodeURIComponent(to.path) : null,
        },
    })
}

export const gotoDashboard = (to?: any, next?: any) => {
    return next({
        name: 'dashboard.index',
    })
}

export const gotoDenied = (to?: any, next?: any) => {
    return next({
        name: '404',
    })
}
