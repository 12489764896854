import { defineStore } from 'pinia'

interface ToastStoreState {
    toastContent: ToastItem|null,
    setTimer: Function | null
}

interface ToastStoreAction {
    showToast(data?: Object): void
}


export interface ToastStore extends ToastStoreState, ToastStoreAction {

}

export const useToastStore = defineStore('toast',{
    state(): ToastStoreState {
        return {
            toastContent: null,
            setTimer: null
        }
    },
    getters: {

    },
    actions: {
        showToast(data?: Object) {
            // @ts-ignore
            this.toastContent = data
            // @ts-ignore
            if(this.setTimer) clearTimeout(this.setTimer)

            // @ts-ignore
            this.setTimer = setTimeout(() => {
                this.toastContent= null
            }, 1400)
        }
    }
})
