import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'maintenance',
            name: 'maintenance.index',
            component: Helpers.lazyload('maintenance/index'),
        },
    ],
}
