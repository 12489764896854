import { Helpers } from '@/plugins/Helpers'
import AuthLayout from '@/views/layouts/AuthLayout.vue'

export default {
    path: '',
    component: AuthLayout,
    children: [
        {
            path: 'auth/login',
            name: 'auth.login',
            component: Helpers.lazyload('auth/login'),
        },
        {
            path: 'auth/register',
            name: 'auth.register',
            component: Helpers.lazyload('auth/register'),
        },
        {
            path: 'auth/forgot-password',
            name: 'auth.forgot-password',
            component: Helpers.lazyload('auth/forgot'),
        },
    ],
}
