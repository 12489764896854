import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { getLanguage } from '@/utils/common';
import { forEach, trim } from 'lodash';
import type { AppStore } from '@/stores/appStore';
import { useAppStore } from '@/stores/appStore';
import { Helpers } from '@/plugins/Helpers';
import MainLayout from '@/views/layouts/MainLayout.vue';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import { scrollToTop, sleeper } from '@/utils/global';

const modulesFiles = require.context('@/router/modules', true, /\.ts$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = (modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')).replace(/-([a-z])/g, g => g[1].toUpperCase());
  const value = modulesFiles(modulePath);
  // @ts-ignore
  modules[moduleName] = value.default;
  return modules;
}, {});

const routes: Array<RouteRecordRaw> = [];

for (const moduleKey in modules) {
  // @ts-ignore
  const module = modules[moduleKey];
  if (Array.isArray(module)) routes.push(...module);
  else routes.push(module);
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:locale(en|vi)',
      children: routes,
    },
    {
      path: '/:catchAll(.*)',
      name: 'catchAll',
      component: MainLayout,
      children: [
        {
          path: '',
          name: '404',
          component: Helpers.lazyload('error/404'),
        },
      ],
    },
  ],
});

const oldMatch = router.resolve;
router.resolve = (rawLocation: any, currentLocation?: any) => {
  if (!rawLocation.params) rawLocation.params = {};
  if (!rawLocation.params.locale) rawLocation.params.locale = getLanguage();
  return oldMatch(rawLocation, currentLocation);
};

let progresses = [] as ProgressFinisher[];
// @ts-ignore
router.beforeEach(async (to, from, next) => {
  progresses.push(useProgress().start());
  let viewport = document.querySelector('meta[name=viewport]');
  if (viewport) viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=no');

  const appStore: AppStore = useAppStore();
  while (!appStore.defaultAsset) {
    await appStore.loadSetting(true);
    await sleeper(500)
  }
  if (!to.params.locale || to.params.locale !== getLanguage()) {
    // Neu la trang chu chua co locale
    if (!trim(to.fullPath, '/').length) {
      return next({ name: 'dashboard.index', params: { locale: getLanguage() } });
    }
  }
  // neu chi la trang chu nhung co locale
  const localeSupport = appStore.languageList.map(v => v.code);
  if (localeSupport.includes(trim(to.fullPath, '/'))) {
    return next({ name: 'dashboard.index', params: { locale: getLanguage() } });
  }
  // @ts-ignore
  if (localeSupport && ['trading.buy', 'trading.sell'].indexOf(to.name) >= 0) {
    const allowCoins = (appStore.listCoin).map((v) => {
      return v.code;
    });
    if (allowCoins.length) {
      if (!to.params.asset) {
        return next({
          // @ts-ignore
          name: to.name,
          params: {
            locale: getLanguage(),
            asset: appStore.defaultAsset,
          },
        });
      }
      if (allowCoins.indexOf(to.params.asset) === -1) {
        return next({
          // @ts-ignore
          name: to.name,
          params: {
            locale: getLanguage(),
            asset: appStore.defaultAsset,
          },
        });
      }
      return next();
    }
    return next();
  }
  return next();
});

router.afterEach(() => {
  if (progresses && progresses.length > 1) {
    forEach(progresses, (item: ProgressFinisher) => {
      item?.finish();
    });
  } else {
    progresses.pop()?.finish();
  }
  scrollToTop();
});

export default router;
