import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'adv-detail/:id',
            name: 'adv.detail',
            component: Helpers.lazyload('trading/detail'),
            meta: {
                auth: true,
            },
        },
    ],
}
