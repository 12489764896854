import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'

export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'page/term-of-use',
            name: 'page.term-of-use',
            component: Helpers.lazyload('page/index'),
        },
        {
            path: 'page/user-policy',
            name: 'page.user-policy',
            component: Helpers.lazyload('page/index'),
        },
    ],
}
