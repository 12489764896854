import { StorageService } from "@/plugins/StorageService";
import moment from "moment-timezone";
import router from "@/router";
import { StorageServiceCookie } from "@/plugins/StorageServiceCookie";


export const getLanguage = (): string => {
  let locale = StorageService.get("locale");
  if (locale) return locale;
  if (document)
    locale = document.getElementsByTagName("html")[0].getAttribute("lang");
  if (!locale) locale = process.env.VUE_APP_LOCALE;
  return (locale ?? "").toString();
};

export const setLanguage = (lang?: string | null): void => {
  if (!lang) lang = getLanguage();
  if (lang) {
    document.querySelector("html")?.setAttribute("lang", lang);
    StorageService.set("locale", lang, 60 * 60 * 24 * 7);
    moment.updateLocale(lang, {});
    router.currentRoute.value.params.locale = lang;
    void router.push(router.currentRoute.value);
  }
};

export const hasToken = (): boolean => {
  return !!StorageServiceCookie.get("token");
  //   return !!StorageService.get("token");
};

export const getToken = (): string => {
  return StorageServiceCookie.get("token", null as any);
  //   return StorageService.get("token", {} as any);
};

export const setToken = (token: string): any => {
  return StorageServiceCookie.set("token", token, 60 * 60 * 24 * 7);
  //   return StorageService.set("token", token, 60 * 60 * 24 * 7);
};

export const clearToken = (): any => {
  return StorageServiceCookie.remove("token");
  //   return StorageService.remove("token");
};

/**
 *
 * @returns {string}
 */
export const getFirebaseToken = (): any => {
  return StorageService.get("firebase-token");
};
export const setFirebaseToken = (token: any): any => {
  return StorageService.set("firebase-token", token);
};
export const removeFirebaseToken = (): any => {
  return StorageService.remove("firebase-token");
};
